$(document).ready(function(){
  

  $('.video__play').on('click', function(event) {
    event.preventDefault();
    $('.video__gag').fadeOut(300);
    $('.video__source')[0].src += "&autoplay=1";
  });

  $('.gallery a').magnificPopup({
    type:'image',
    gallery: {
      enabled: true
    }
  });
  
  
  
});
